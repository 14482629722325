@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

a {
  @apply text-skin-muted;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer base {
  /* :root {
    --color-text-base: #333;
    --color-text-muted: #666;
    --color-text-inverse: #fff;
    --color-fill: #007bff;
    --color-button-accent: #007bff;
    --color-button-accent-hover: #0056b3;
    --color-button-accent-muted: #e9f0f7;
  } */
  @media (prefers-color-scheme: dark) {
    :root {
      --color-text-base: 255 241 241;
      --color-text-muted: 204 204 204;
      --color-text-inverse: 3 7 18;
      --color-text-hover: 255 251 251;
      --color-fill: 3 7 18;
      --color-button-accent: 239 90 111; /* #ef5a6f */
      --color-button-accent-secondary: 83 100 147; /* #536493 */
      --color-button-accent-hover: 83 83 81; /* #535333 */
      --color-button-accent-muted: 23 23 21; /* #171715 */
    }
  }
  :root[data-theme="dark"] {
    --color-text-base: 255 251 251;
    --color-text-muted: 204 204 204;
    --color-text-inverse: 3 7 18;
    --color-text-hover: 255 251 251;
    --color-fill: 3 7 18;
    --color-button-accent: 239 90 111;
    --color-button-accent-secondary: 83 100 147; /* #536493 */
    --color-button-accent-hover: 83 83 81;
    --color-button-accent-muted: 23 23 21;
  }
  :root[data-theme="light"] {
    --color-text-base: 3 7 18;
    --color-text-muted: 102 102 102; /* #666 */
    --color-text-inverse: 255 251 251;
    --color-text-hover: 241 241 241;
    --color-fill: 251 251 251;
    --color-button-accent: 239 90 111;
    --color-button-accent-secondary: 83 100 147; /* #536493 */
    --color-button-accent-hover: 241 241 241;
    --color-button-accent-muted: 233 240 247;
  }
}

code {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  font-size: 1em;
  font-weight: 600;
  background-color: --color-button-accent;
  color: --color-text-base;
  padding: 0.2em 0.4em;
  border-radius: 0.3em;
  margin: 0 0.2em;
  display: inline-block;
}
